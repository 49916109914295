import { combineReducers } from 'redux';
import loopQualAppReducer from '../components/loop-qual-app/reducer';
import addressQualificationReducer from '../components/address-qualification/reducer';
import addressLookupReducer from '../components/address-lookup/reducer';
import unitLookupReducer from '../components/units-lookup/reducer';
import floorDetailsReducer from '../components/floor-lookup/reducer';
import notifyReducer from '../components/service-unavailable/reducer';
import loaderReducer from '../components/common/loader/reducer';
import modalOverlay from '../components/common/modal/reducer';
import fiosReducer from '../components/order-fios/reducer';
import fiveGReducer from '../components/order-fiveg-home/reducer';
import moveReducer from '../components/move-lq/reducer';
import bulkReducer from '../components/bulk-lq/reducer';

const rootReducer = combineReducers({
  loopQualApp: loopQualAppReducer,
  addressQualification: addressQualificationReducer,
  addressLookup: addressLookupReducer,
  unitLookup: unitLookupReducer,
  floorDetails: floorDetailsReducer,
  notify: notifyReducer,
  loader: loaderReducer,
  modalOverlay,
  fiosDetails: fiosReducer,
  fiveGDetails: fiveGReducer,
  moveDetails: moveReducer,
  bulkQualDetails: bulkReducer,
});

export default rootReducer;
