import { getCookie } from './utils/lqServiceUtil';

const configDefaults = {
  targetElementId: 'lq-widget-root',
  lqApi: 'LOCUS_API',
  platform: 'ALL',
  scTag: 'wireline',
  tws: '',
  crossSell: '',
  flowType: '',
  seqType: '',
  fiosInquire: '',
  autoQualOrderingRedirect: '',
  renderSettings: {
    mode: 'modal',
    externalTrigger: ['.checkAvailabilityBtn'],
    miniLq: {
      enabled: true,
      footer: '',
      layout: {
        default: {
          topPosition: '69px',
          zIndex: '900',
        },
        mq_lg: {
          breakPoint: '1020px',
          topPosition: '120px',
          zIndex: '899',
        },
      },
    },
  },
};

function getConfig() {
  return window.vzwlqwidgetconfig || configDefaults;
}

export const getLQPlatform = () => getConfig().platform || configDefaults.platform;

export const getTwsFlow = () => getConfig().tws || configDefaults.tws;

export const getCrossSell = () => getConfig().crossSell || configDefaults.crossSell;

export const getLQTagform = () => getConfig().scTag || configDefaults.scTag;

export const getRenderSettings = () => getConfig().renderSettings || configDefaults.renderSettings;

export const getFlowType = () => getConfig().flowType || configDefaults.flowType;

export const getSeqType = () => getConfig().seqType || configDefaults.seqType;

export const getTwsStickyLQ = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const istwsStickyLa = urlSearchParams.get('twsStickyLq');
  return istwsStickyLa || getConfig().twsStickyLq || configDefaults.twsStickyLq;
};
export const getLoggedInTwsStickyLQ = () => {
  const params = new URLSearchParams(window.location.search);
  const isLoggedIn = getCookie('loggedIn') === 'true' || getCookie('userinfo');
  const twsSrcInfo = getSourceInfo();
  let twsFlow = getTwsFlow() ? getTwsFlow() : params.get('type') ? params.get('type') : null;
  if (
    (twsFlow &&
      (twsFlow === 'medical' ||
        twsFlow === 'student' ||
        twsFlow === 'medical_new' ||
        twsFlow === 'responder' ||
        twsFlow === 'teacher' ||
        twsFlow === 'milvet' ||
        twsFlow === 'nurse')) ||
    (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1)
  ) {
    if (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1) {
      twsFlow = twsSrcInfo.replace('tws_', '');
    }
    if (twsFlow === 'medical') {
      twsFlow = 'medical_new';
    }
    return isLoggedIn && twsFlow;
  }
  return false;
};

export const getSourceInfo = () => getConfig().sourceInfo || configDefaults.sourceInfo || getConfig().source || configDefaults.source;

export const getFooter = () =>
  (getConfig().renderSettings && getConfig().renderSettings.miniLq && getConfig().renderSettings.miniLq.footer) ||
  (configDefaults.renderSettings && configDefaults.renderSettings.miniLq && configDefaults.renderSettings.miniLq.footer);

export const getFiosInquire = () => getConfig().fiosInquire || configDefaults.fiosInquire;

export default getConfig();
