import api from './apiGateway';
import { getReqHeader } from '../utils/lqServiceUtil';

const callingApi = (url, methodType, data, contentType, reqHeader) =>
  new Promise((resolve, reject) => {
    const requestObj = getReqHeader(url, methodType, data, contentType, reqHeader);
    if (window.location.host.includes('localhost')) {
      // to prevent preflight issue for localhost
      requestObj.withCredentials = false;
    }
    api(requestObj)
      .then((response) => {
        resolve(response);
        console.log(url, response);
      })
      .catch((error) => {
        if (window.location.host.includes('localhost')) {
          // no reject
        } else {
          reject(error);
        }
        console.log(url, error);
      });
  });

export default callingApi;
