import React from 'react';
import { LQ_VIEWS } from '../../constants/common';
import Loader from '../common/loader/Loader';

const AddressQualification = React.lazy(() => import('../address-qualification'));
const ServiceUnavailable = React.lazy(() => import('../service-unavailable'));
const OrderFiveGHome = React.lazy(() => import('../order-fiveg-home'));
const OrderFios = React.lazy(() => import('../order-fios'));
const OrderLteHome = React.lazy(() => import('../order-lte-home'));
const ResumeSmartCart = React.lazy(() => import('../resume-smart-cart'));
const BusinessAddress = React.lazy(() => import('../business-address'));
const Commonlq = React.lazy(() => import('../common-lq'));
const MoveLq = React.lazy(() => import('../move-lq'));
const BulkLq = React.lazy(() => import('../bulk-lq'));
const Lbo = React.lazy(() => import('../lbo'));
const Loading = React.lazy(() => import('../common/loader/loading.js'));
const VFDiscountView = React.lazy(() => import('../verizonForward/VFDiscountView'));
const VFQuestionaireView = React.lazy(() => import('../verizonForward/VFQuestionaireView'));
const InitalLoader = React.lazy(() => import('../initial-loader'));

export const getCurrentBaseView = (currentLQView) => {
  let returnComp;
  switch (currentLQView) {
    case LQ_VIEWS.LBO_VIEW:
      returnComp = <Lbo />;
      break;
    case LQ_VIEWS.UN_QUALIFIED_VIEW:
      returnComp = <ServiceUnavailable />;
      break;
    case LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW:
      returnComp = <OrderFiveGHome />;
      break;
    case LQ_VIEWS.FIOS_QUALIFIED_VIEW:
      returnComp = <OrderFios />;
      break;
    case LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW:
      returnComp = <OrderLteHome />;
      break;
    case LQ_VIEWS.RESUME_SMART_CART_VIEW:
      returnComp = <ResumeSmartCart />;
      break;
    case LQ_VIEWS.BUSINESSADDRESS_VIEW:
      returnComp = <BusinessAddress />;
      break;
    case LQ_VIEWS.COMMON_LQ:
      returnComp = <Commonlq />;
      break;
    case LQ_VIEWS.VF_QUAL_FWA:
      returnComp = <VFDiscountView />;
      break;
    case LQ_VIEWS.VF_QUESTIONAIRE:
      returnComp = <VFQuestionaireView />;
      break;
    case LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW:
      returnComp = <MoveLq />;
      break;
    case LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW:
      returnComp = <BulkLq />;
      break;
    case LQ_VIEWS.FIOS_LOADING_VIEW:
      returnComp = <Loading />;
      break;
    case LQ_VIEWS.BLANK_LOADER_VIEW:
      returnComp = <InitalLoader/>;
      break;
    default:
      returnComp = <AddressQualification />;
  }
  return (
    <React.Suspense fallback={<Loader active />}>
      <div>{returnComp}</div>
    </React.Suspense>
  );
};
