import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const headerRoot = document.querySelector('header#vz-gh20');
const headerDiv = document.querySelector('div#vz-gh20');
const headerAem =
  document.getElementsByClassName('header') && document.getElementsByClassName('header')[0] ? document.getElementsByClassName('header')[0] : '';
const retailBanner =
  document.querySelectorAll('[role="banner"]') && document.querySelectorAll('[role="banner"]')[0]
    ? document.querySelectorAll('[role="banner"]')[0]
    : '';
export default class DomComponent extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = 'lqWidgetComp';
  }

  componentDidMount() {
    if (retailBanner && window.location.pathname.includes('/home/retail/addresslookup')) {
      retailBanner.parentNode.insertBefore(this.el, retailBanner.nextSibling);
    } else if (headerAem) {
      headerAem.parentNode.insertBefore(this.el, headerAem.nextSibling);
    } else if (headerRoot) {
      headerRoot.parentNode.insertBefore(this.el, headerRoot.nextSibling);
    } else if (headerDiv) {
      headerDiv.parentNode.insertBefore(this.el, headerDiv.nextSibling);
    } else {
      document.body.insertBefore(this.el, document.body.firstChild);
    }
  }

  componentWillUnmount() {
    if (retailBanner && window.location.pathname.includes('/home/retail/addresslookup')) {
      retailBanner.parentNode.removeChild(this.el);
    } else if (headerAem) {
      headerAem.parentNode.removeChild(this.el);
    } else if (headerRoot) {
      headerRoot.parentNode.removeChild(this.el);
    } else if (headerDiv) {
      headerDiv.parentNode.removeChild(this.el);
    } else {
      document.body.removeChild(this.el);
    }
  }

  // updateLayout = (style) => {
  //   console.log('style', style);
  //   const offsetHeight =
  //     document.getElementsByClassName('minilq') &&
  //     document.getElementsByClassName('minilq')[0] &&
  //     document.getElementsByClassName('minilq')[0].offsetHeight
  //       ? `${document.getElementsByClassName('minilq')[0].offsetHeight}`
  //       : '110';
  //   this.el.style.setProperty('height', `${offsetHeight}px`, 'important');
  // };

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
DomComponent.propTypes = {
  children: PropTypes.string,
};
