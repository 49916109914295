export const getFullAddress = (addressInfo) => {
  if (!addressInfo) return '';

  const { addressLine1, city, state, zipCode } = addressInfo;
  if (addressLine1 || city || state || zipCode) {
    const address = [];
    if (addressLine1) address.push(addressLine1);

    if (city) address.push(city);
    if (state) address.push(state);
    if (zipCode) address.push(zipCode.slice(0, 5));

    const fullAddress = address.length ? address.join(', ') : '';
    return fullAddress;
  }

  return '';
};
