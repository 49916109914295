export function isNotNull(value) {
  if (typeof value !== 'string' && value !== undefined && value !== null) {
    return true;
  }
  if (typeof value === 'string' && value !== undefined) {
    return true;
  }
  return false;
}

export function setModalScroll(offset = 0) {
  const scrollEl = document.querySelector('#lqw-modal-container #scrollbar-view') || document.querySelector('#lqw-modal-container');
  if (scrollEl) {
    scrollEl.scrollTop = offset;
  }
}
