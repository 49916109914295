export const LoopQualification = {
  SET_FLOW_TYPE: 'SET_FLOW_TYPE',
  UPDATE_CURRENT_VIEW: 'UPDATE_CURRENT_VIEW',
  UPDATE_RECAPTCHA_DETAILS: 'UPDATE_RECAPTCHA_DETAILS',
  UPDATE_RECAPTCHA_RESPONSE: 'UPDATE_RECAPTCHA_RESPONSE',
  UPDATE_RECAPTCHA_PROPERTY: 'UPDATE_RECAPTCHA_PROPERTY',
  UPDATE_SWEEP_STAKE: 'UPDATE_SWEEP_STAKE',
  SET_LQ_PLATFORM: 'SET_LQ_PLATFORM',
  SET_LQ_TAGFORM: 'SET_LQ_TAGFORM',
  SET_TWS: 'SET_TWS',
  SET_ACP: 'SET_ACP',
  SET_VFEC: 'SET_VFEC',
  SET_MDU: 'SET_MDU',
  SET_MVPH2: 'SET_MVPH2',
  SET_MVPH2_TITAN: 'SET_MVPH2_TITAN',
  SET_MVPH2_DISABLE: 'SET_MVPH2_DISABLE',
  HIDE_FCC_MINILQ: 'HIDE_FCC_MINILQ',
  IS_FCC_FLOW: 'IS_FCC_FLOW',
  MIDNIGHT: 'MIDNIGHT',
  SET_COMBOMFJT: 'SET_COMBOMFJT',
  SET_LQ4: 'SET_LQ4',
  SET_NEW_CONNECTION: 'SET_NEW_CONNECTION',
  SET_BULKQUAL: 'SET_BULKQUAL',
  SET_MHTOKEN: 'SET_MHTOKEN',
  SET_SOURCE_INFO: 'SET_SOURCE_INFO',
  UPDATE_LOGGED_IN: 'UPDATE_LOGGED_IN',
  NO_LQA_MODAL: 'NO_LQA_MODAL',
  UPDATE_LOGGED_IN_USERINFO: 'UPDATE_LOGGED_IN_USERINFO',
  ROLE_TYPE: 'ROLE_TYPE',
  UPDATE_WIDGET_HOST: 'UPDATE_WIDGET_HOST',
  REDIRECT_TO_PLANS_PAGE: 'REDIRECT_TO_PLANS_PAGE',
  SET_STATIC_CONTENT: 'SET_STATIC_CONTENT',
  SET_CHECKAVAILBTNTRACK: 'SET_CHECKAVAILBTNTRACK',
  SET_RUNTIME_KEY: 'SET_RUNTIME_KEY',
  UPDATE_THROTTLE_RESPONSE: 'UPDATE_THROTTLE_RESPONSE',
  UPDATE_COMMON_LQ: 'UPDATE_COMMON_LQ',
  UPDATE_VALID_COUPONFLOW: 'UPDATE_VALID_COUPONFLOW',
  UPDATE_CUSTOMER_TYPE: 'UPDATE_CUSTOMER_TYPE',
  SET_NYCPH2: 'SET_NYCPH2',
  SET_RESP_LQ: 'SET_RESP_LQ',
  SET_VF_NEW: 'SET_VF_NEW',
  SET_VF_EMAIL_NC: 'SET_VF_EMAIL_NC',
  SET_VF_RESUME_FLOW: 'SET_VF_RESUME_FLOW',
  SET_VF_ENROLL_CHECK: 'SET_VF_ENROLL_CHECK',
};

export const FloorLookUp = {
  UPDATE_FLOOR_NUMBER: 'UPDATE_FLOOR_NUMBER',
};

export const AddressQualification = {
  QUALIFICATION_DETAILS: 'QUALIFICATION_DETAILS',
  SC_EMAIL: 'SC_EMAIL',
  SC_MTN: 'SC_MTN',
  SC_DETAILS: 'SC_DETAILS',
  SC_ACP: 'SC_ACP',
  SC_VFP: 'SC_VFP',
  EMERIOS_BODY: 'EMERIOS_BODY',
  FORMATTED_MTN: 'FORMATTED_MTN',
  HELP_VIEW: 'HELP_VIEW',
  RESTORE_DEFAULTS: 'RESTORE_DEFAULTS',
  RESET_QUALIFICATION_DETAILS: 'RESET_QUALIFICATION_DETAILS',
  LQSTAG_TRIGGERED: 'LQSTAG_TRIGGERED',
  MAPTAG_TRIGGERED: 'MAPTAG_TRIGGERED',
  RESET_QUALIFICATION_DETAILS_LQRESULT: 'RESET_QUALIFICATION_DETAILS_LQRESULT',
  VALIDATE_EMAIL_REGEX: 'VALIDATE_EMAIL_REGEX',
  VALIDATE_EMAIL_ADDRESS: 'VALIDATE_EMAIL_ADDRESS',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  MANUAL_VALIDATION_CLICK: 'MANUAL_VALIDATION_CLICK',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  ERROR_TEXT_FOR_EMAIL: 'ERROR_TEXT_FOR_EMAIL',
  SMARTCART_PDM: 'SMARTCART_PDM',
};
export const MovesQualification = {
  PROFILE_QUALIFICATION_DETAILS: 'PROFILE_QUALIFICATION_DETAILS',
  SET_PREFERRED_MV_SERVICE: 'SET_PREFERRED_MV_SERVICE',
  IS_PENDING_MOVE_SERVICE: 'IS_PENDING_MOVE_SERVICE',
  IS_BALANCE_DUE: 'IS_BALANCE_DUE',
  IS_DELAYED_BILLING: 'IS_DELAYED_BILLING',
  DIRECT_MOVE: 'DIRECT_MOVE',
  DISCONNECT_MOVE: 'DISCONNECT_MOVE',
  RESET_MV_DETAILS: 'RESET_MV_DETAILS',
};
export const BulkQualification = {
  PROFILE_BULK_QUALIFICATION_DETAILS: 'PROFILE_BULK_QUALIFICATION_DETAILS',
  SET_PREFERRED_BULK_SERVICE: 'SET_PREFERRED_BULK_SERVICE',
  SET_PREFERRED_BULK_SERVICE_DETAIL: 'SET_PREFERRED_BULK_SERVICE_DETAIL',
  RESET_BULK_DETAILS: 'RESET_BULK_DETAILS',
  SET_SINGLE_BULKQUAL: 'SET_SINGLE_BULKQUAL',
  SET_QUALIFIED: 'SET_QUALIFIED',
  FWA_EXISTING: 'FWA_EXISTING',
  SELECTED_LISTED: 'SELECTED_LISTED',
};
export const AddressLookUp = {
  ON_STREET_SELECTION: 'ON_STREET_SELECTION',
  RESET_STREET_SELECTION: 'RESET_STREET_SELECTION',
  UPDATE_STREET_DETAILS: 'UPDATE_STREET_DETAILS',
  UPDATE_STREET_INPUT: 'UPDATE_STREET_INPUT',
  STREET_EMPTY_ERROR: 'STREET_EMPTY_ERROR',
  SET_STREET_ERROR: 'SET_STREET_ERROR',
  SET_STREET_GEO_ERROR: 'SET_STREET_GEO_ERROR',
  SAME_ADDRESS_ERROR: 'SAME_ADDRESS_ERROR',
  INVALID_ADDRESS_ERROR: 'INVALID_ADDRESS_ERROR',
  UPDATE_ADDRESS_COLLECTION: 'UPDATE_ADDRESS_COLLECTION',
  FULL_SUGGESTION: 'FULL_SUGGESTION',
  HALF_SUGGESTION: 'HALF_SUGGESTION',
  UPDATE_ADDRESS_COLLECTION_RESET: 'UPDATE_ADDRESS_COLLECTION_RESET',
  RESTORE_DEFAULTS: 'RESTORE_DEFAULTS',
  ERROR_MSG: 'ERROR_MSG',
  STARTTIMESTREETSELECT: 'STARTTIMESTREETSELECT',
  REVISIT_ADDRESS: 'REVISIT_ADDRESS',
  SHOW_LQCTA: 'SHOW_LQCTA',
  PARSER_ATTEMPT: 'PARSER_ATTEMPT',
  SET_PARSED_ADDRESS: 'SET_PARSED_ADDRESS',
  PARSER_ADDRESS_ID: 'PARSER_ADDRESS_ID',
  PARSER_SHOW_ALL: 'PARSER_SHOW_ALL',
};

export const UnitLookUp = {
  UPDATE_UNIT_DETAILS: 'UPDATE_UNIT_DETAILS',
  RESET_UNIT_DETAILS: 'RESET_UNIT_DETAILS',
  UPDATE_UNIT_COLLECTION: 'UPDATE_UNIT_COLLECTION',
  UPDATE_UNIT_REQUIREMENT: 'UPDATE_UNIT_REQUIREMENT',
  RESTORE_DEFAULTS: 'RESTORE_DEFAULTS_UNIT_LOOKUP',
  UPDATE_UNIT_INPUT: 'UPDATE_UNIT_INPUT',
  UNIT_SELECTED: 'UNIT_SELECTED',
  UPDATE_UNIT_AUTOCOMPLET_LIST: 'UPDATE_UNIT_AUTOCOMPLET_LIST',
  UPDATE_DROPDOWN_LIMIT_STATUS: 'UPDATE_DROPDOWN_LIMIT_STATUS',
  UPDATE_FETCHED_UNITS_STREET_ID: 'UPDATE_FETCHED_UNITS_STREET_ID',
  SET_UNIT_INPUT_SUCCESS: 'SET_UNIT_INPUT_SUCCESS',
  SET_UNIT_INPUT_ERROR: 'SET_UNIT_INPUT_ERROR',
  UPDATE_USER_APARTMENT: 'UPDATE_USER_APARTMENT',
};

export const Loader = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
};

export const Modal = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_TITLE: 'SET_TITLE',
  EXISTINGCUSTOMER_MODAL: 'EXISTINGCUSTOMER_MODAL',
};

export const ServiceUnavailable = {
  RESET_EMAIL_PHONE: 'RESET_EMAIL_PHONE',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  FORMATTED_PHONE: 'FORMATTED_PHONE',
  EMAIL_VALID_FLAG: 'EMAIL_VALID_FLAG',
  PHONE_VALID_FLAG: 'PHONE_VALID_FLAG',
  RESTORE_DEFAULTS: 'RESTORE_DEFAULTS',
  SIGN_UP_COMPLETED: 'SIGN_UP_COMPLETED',
  MOVE_SIGNUP_CONTINUE: 'MOVE_SIGNUP_CONTINUE',
  RETRIEVE_CUSTOMER_CONSENT: 'RETRIEVE_CUSTOMER_CONSENT',
  RETRIEVE_CUSTOMER_CONSENT_TRIGGERED: 'RETRIEVE_CUSTOMER_CONSENT_TRIGGERED',
  SMSCONSENT_SPID: 'SMSCONSENT_SPID',
  NOTIFY_FIOS: 'NOTIFY_FIOS',
  NOTIFY_FIOSTV: 'NOTIFY_FIOSTV',
  NOTIFY_FIVEG: 'NOTIFY_FIVEG',
};

export const Fios = {
  SAVE_RESPONSE: 'SAVE_RESPONSE',
  FIOSQUALIFIED_VIEW: 'FIOSQUALIFIED_VIEW',
  INSERVICE_VIEW: 'INSERVICE_VIEW',
  RESUMECART_VIEW: 'RESUMECART_VIEW',
  RESUMESENDCART_VIEW: 'RESUMESENDCART_VIEW',
  DISCOUNT_MOBILE_HOME: 'DISCOUNT_MOBILE_HOME',
  PROCESSSAVECART_INVOKED: 'PROCESSSAVECART_INVOKED',
  SENT_SMSOREMAIL: 'SENT_SMSOREMAIL',
  NEW_SERVICE: 'NEW_SERVICE',
  RESTOREFIOS_DEFAULTS: 'RESTOREFIOS_DEFAULTS',
  SIGNIN_VIEW: 'SIGNIN_VIEW',
  SIGNIN_VERIFICATION_VIEW: 'SIGNIN_VERIFICATION_VIEW',
  SIGNIN_BASIC_PHONE_VERIFICATION_VIEW: 'SIGNIN_BASIC_PHONE_VERIFICATION_VIEW',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  ENABLE_VERIFY_BUTTON: 'ENABLE_VERIFY_BUTTON',
  MH_MTNUMBER: 'MH_MTNUMBER',
  MH_ZIPCODE: 'MH_ZIPCODE',
  VALIDATE_RESPONSE: 'VALIDATE_RESPONSE',
  MH_VERIFICATIONCODE: 'MH_VERIFICATIONCODE',
  MH_ELIGIBLE: 'MH_ELIGIBLE',
  API_COUNT: 'API_COUNT',
  ERROR_SIGNIN_MH: 'ERROR_SIGNIN_MH',
  IS_LOGIN_AUTHENTICATED: 'IS_LOGIN_AUTHENTICATED',
  VENDOR_DETAILS: 'VENDOR_DETAILS',
  SET_REFERRAL_URL: 'SET_REFERRAL_URL',
  IG_VERIFICATION_START: 'IG_VERIFICATION_START',
  // SIGNUP_CHECK: 'SIGNUPCHECK',
};

export const FiveG = {
  RELOAD_MAP: 'RELOAD_MAP',
  SHOW_MAP: 'SHOW_MAP',
  MAP_COMPLETED: 'MAP_COMPLETED',
  APT_FLR_INPUT_COMPLETED: 'APT_FLR_INPUT_COMPLETED',
  RESTOREFIVEG_DEFAULTS: 'RESTOREFIVEG_DEFAULTS',
};

export const MultiService = {
  SET_PREFERRED_SERVICE: 'SET_PREFERRED_SERVICE',
  SET_IS_PREFERRED_SERVICE_QUALIFIED: 'SET_IS_PREFERRED_SERVICE_QUALIFIED',
  SET_OTHER_QUALIFIED_SERVICES: 'SET_OTHER_QUALIFIED_SERVICES',
};

export const PAGEACTIVITY = {
  PAGE: {
    LQ2: 'LOOPQUAL2.0',
  },
  MSG: {
    serviceUnAvailable: 'SERVICEUNAVAILABLE',
    OnStreetSearchSuccess: 'ONSTREETSEARCHSUCCESS',
    OnStreetSearchFailure: 'ONSTREETSEARCHFAILURE',
    fiosExistingServiceSuccess: 'FIOSEXISTINGSERVICESUCCESS',
    fiosExisitingSeviceFailure: 'FIOSEXISTINGSERVICEFAILURE',
    processSaveCartSuccess: 'PROCESSSAVECARTSUCCESS',
    processSaveCartFailure: 'PROCESSSAVECARTFAILURE',
    sendSmsOrEmailSuccess: 'SENDSMSOREMAILSUCCESS',
    sendSmsOrEmailFailure: 'SENDSMSOREMAILFAILURE',
    cancelSmartcartSuccess: 'CANCELSMARTCARTSUCCESS',
    cancelSmartcartFailure: 'CANCELSMARTCARTFAILURE',
    reloadReCaptchaDetailsSuccess: 'RELOADRECAPTCHADETAILSSUCCESS',
    reloadReCaptchaDetailsFailure: 'RELOADRECAPTCHADETAILSFAILURE',
    checkAvailabilityContinueClick: 'CHECKAVAILABILITYCONTINUECLICK',
    onCaptcheComplete: 'ONCAPTCHECOMPLETE',
    onHelpClick: 'ONHELPCLICK',
    AddressBarOnclick: 'ADDRESSBARONCLICK',
    floorLookUpContinueClick: 'FLOORLOOKUPCONTINUECLICK',
    miniLq: 'MINILQADDRESSCHANGE',
    orderNow: 'ORDERNOW',
    signIn: 'SIGNIN',
    signInNow: 'SIGNIN_NOW',
    signInVerification: 'SIGNINVERIFICATION',
    exisitingCustomerClick: 'EXISTINGCUSTOMERCLICK',
    pendingOrder: 'PENDINGORDER',
    pendingOrderYes: 'PENDINGORDERYESCLICK',
    pendingOrderNo: 'PENDINGORDERNOCLICK',
    resumecartContinueSaveCartClick: 'RESUMECARTCONTINUESAVECARTCLICK',
    resumecartNeworderClick: 'RESUMECARTNEWORDERCLICK',
    orderNowFiveGExistingCustomer: '5GEXISTINGCUSTOMER',
    orderNowFiveGNewCustomer: '5GNEWCUSTOMER',
    lteNewCustomer: 'LTENEWCUSTOMER',
    lteExistingCustomer: 'LTEEXISTINGCUSTOMER',
    resumeSmartCartNewOrderClick: 'RESUMESMARTCARTNEWORDERCLICK',
    resumeCartClick: 'RESUMECARTCLICK',
    serviceUnavailableSignUpClick: 'SERVICEUNAVIALBLESIGNUPCLICK',
    fetchAuthDetailsSuccess: 'FETCHAUTHDETAILSSUCCESS',
    fetchAuthDetailsFailure: 'FETCHAUTHDETAILSFAILURE',
    throttleCheckSuccess: 'THROTTLECHECKSUCCESS',
    throttleCheckFailure: 'THROTTLECHECKFAILURE',
    coverageLeadSuccess: 'COVERAGELEADSUCCESS',
    coverageLeadFailure: 'COVERAGELEADFAILURE',
    vzwOfferEligibilitySuccess: 'VZWOFFERELIGIBILITYSUCCESS',
    vzwOfferEligibilityFailure: 'VZWOFFERELIGIBILITYFAILURE',
    signinMHSuccess: 'SIGNINMHSUCCESS',
    signinMHFailure: 'SIGNINMHFAILURE',
    callValidateAPIFailure: 'CALLVALIDATEAPIFAILURE',
    callCheckPrelinkAPIFailure: 'CALLCHECKPRELINKAPIFAILURE',
    callCheckPrelinkAPISuccess: 'CALLCHECKPRELINKAPISUCCESS',
    getvendorvisitdetailsSuccess: 'GETVENDORVISITDETAILSUCCESS',
    getvendorvisitdetailsFailure: 'GETVENDORVISITDETAILFAILURE',
    checkLoggedInMVSuccess: 'CHECKLOGGEDINMVSUCCESS',
    checkLoggedInMVFailure: 'CHECKLOGGEDINMVFAILURE',
    checkLoggedInBKSuccess: 'CHECKLOGGEDINBKSUCCESS',
    checkLoggedInBKFailure: 'CHECKLOGGEDINBKFAILURE',
  },
  UA: 'USERACTION',
};
