/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body, Title } from '@vds/typography';
import { ButtonIcon } from '@vds/button-icons';
import { Icon } from '@vds/icons';
import { openModalWindow } from '../common/modal/action';
import { updateCurrentView, clearDetails, updateHideMiniLq } from '../loop-qual-app/actions';
import { LQ_VIEWS, Flowtype } from '../../constants/common';
import Button from '../common/Button';
import StickyBarTreatA from '../common/sticky-bar-Treat-A';
import { stickyBannerContent, lqServices } from '../../constants/lq-constants';
import AddressEditable from '../common/address-editable';
import AddressEditableMinilq from '../common/address-editable-minilq';
import { dataTrackwithDetail, dataTrack } from '../../utils/vzdl';
import { openViewVzTag } from '../../utils/unifiedVzTag';
import { getQualifiedService, getServiceName, getMVServiceName } from '../../utils/lqServiceUtil';
import { logMetrics } from '../../services/logService';
import AddressLookup from '../address-lookup';
import Link from '../common/Link';
import UnitLookup from '../units-lookup';

import { greyRemove } from '../address-qualification/actions';
import { getTwsStickyLQ } from '../../config';

const CloseWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;
const StyledButton = styled(Button)`
  background-color: ${(props) => props?.colors?.backgroundColor || '#fff'};
  border: ${(props) => (props?.colors?.borderColor ? `1px solid ${props?.colors?.borderColor}` : '#fff')};
  color: ${(props) => props?.colors?.fontColor || '#000'};
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props?.colors?.backgroundColor || '#fff'};
    border-color: ${(props) => props?.colors?.color || '#fff'};
    color: ${(props) => props?.colors?.fontColor || '#000'};
    text-decoration: none;
  }
  height: 44px;
  @media (max-width: 767px) {
    height: 32px;
    font-size: 12px;
    span {
      display: inline;
    }
  }
`;

const Line = styled.div`
  height: 0.0625rem;
  width: 100%;
  background-color: #aeaeae;
  margin-top: 16px;
  margin-bottom: 16px;
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: block;
  }
`;
const TwoColWrapper = styled.div`
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
    justify-content: ${(props) => (props.justifyNormal ? 'normal' : 'space-between')};
    padding: 0;
    max-width: 1272px;
    margin: 0 auto;
    height: inherit;
  }
  @media (min-width: 1024px) {
    justify-content: ${(props) => (props.addressAvailable ? 'space-between' : props.justifyNormal ? 'center' : 'space-between')};
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;
const PaddingforLinkText = styled.div`
  padding-left: 16px;
  padding-right: 10px;
`;

const LeftColForExpCol = styled.div`
  @media (max-width: 767px) {
    margin-top: 0px;
  }
  .float-left-child {
    float: left;
    width: 100%;
    @media (max-width: 767px) {
      width: 24%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .float-left-signup-child {
    float: left;
    width: 83%;
    @media (max-width: 767px) {
      width: 24%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
  }
  .float-right-child {
    float: left;
    padding-top: 0.5rem;
  }
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
  @media (min-width: 1024px) {
    width: ${(props) => (props.addressAvailable ? '44%' : props.autoWidth ? '300px' : '44%')};
    padding-left: 20px;
    height: inherit;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    padding-left: 0%;
    height: auto;
  }
  #left-content * {
    color: ${(props) => props?.colors?.color || props.color || '#ffffff'};
  }
  #left-content a:hover {
    border-bottom-color: ${(props) => props?.colors?.color || props.color || '#000'};
  }
  #left-content {
    #edit-address {
      border-color: ${(props) => props.colors?.color || '#FFF'};
      outline-color: ${(props) => props.colors?.color || '#FFF'};
    }
  }
  #address-edit-wrapper {
    @media (max-width: 767px) {
      :after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
      }
    }
  }
  #ae-street-wrapper {
    p {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    @media (max-width: 767px) {
      float: left;
      width: 80%;
    }
    @media (min-width: 768px) {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
  }
  #ae-edit-wrapper {
    @media (max-width: 767px) {
      float: right;
    }
    @media (min-width: 768px) {
      display: inline-block;
      margin-left: 1rem;
    }
  }
`;
const LeftColWrapper = styled.div`
  @media (min-width: 1024px) {
    margin-left: ${(props) => (props.addressAvailable ? 'inherit' : 'auto')};
  }
`;
const LeftColForReskin = styled.div`
  .float-left-child {
    float: left;
    width: 100%;
    @media (max-width: 767px) {
      width: 24%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .float-left-signup-child {
    float: left;
    width: 83%;
    @media (max-width: 767px) {
      width: 24%;
    }
    @media (max-width: 768px) {
      width: 60%;
    }
  }
  .float-right-child {
    float: left;
    padding-top: 0.5rem;
  }
  padding-bottom: 0.5rem;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) {
    width: 60%;
    padding-bottom: 0;
  }
  #left-content * {
    color: ${(props) => props?.colors?.color || props.color || '#ffffff'};
  }
  #left-content a:hover {
    border-bottom-color: ${(props) => props?.colors?.color || props.color || '#000'};
  }
  #left-content {
    #edit-address {
      border-color: ${(props) => props.colors?.color || '#FFF'};
      outline-color: ${(props) => props.colors?.color || '#FFF'};
    }
  }
  #address-edit-wrapper {
    @media (max-width: 767px) {
      :after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
      }
    }
  }
  #ae-street-wrapper {
    p {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    @media (max-width: 767px) {
      float: left;
      width: 80%;
    }
    @media (min-width: 768px) {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
  }
  #ae-edit-wrapper {
    @media (max-width: 767px) {
      float: right;
    }
    @media (min-width: 768px) {
      display: inline-block;
      margin-left: 1rem;
    }
  }
`;
const RightCol = styled.div`
  display: flex;
  align-items: center;
  padding-right: 150px;
  @media (max-width: 767px) {
    padding-top: 10px;
  }
`;
const RightColForExpand = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0px;
  @media (max-width: 767px) {
    padding-top: 16px;
  }
  @media (min-width: 1024px) {
    padding-right: 8%;
    margin-top: 9px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin-top: 22px;
  }
  @media (min-width: 1024px) {
    // padding-top: 44px;
    // padding-bottom: 24px;
  }
`;
const OtherServiceContent = styled.div`
  padding-top: 0.5rem;
  *{
  @media (min-width:1024px){
  ${(props) => (props.reduceSize ? `font-size:${props.reduceSize}!important;` : '')}
  }
  }
}
`;
const Space = styled.div`
  width: 20px;
  height: auto;
  display: inline-block;
`;

class MiniLq extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lq4DataTrack: `${this.props.loopQualApp.checkAvailBtnTrack} |header `,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.mapDataTrack);
  }

  componentDidUpdate(preProps) {
    if (preProps.loopQualApp.checkAvailBtnTrack !== this.props.loopQualApp.checkAvailBtnTrack) {
      this.mapDataTrack();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.mapDataTrack);
  }

  mapDataTrack = () => {
    if (
      document.documentElement.scrollTop > 200 &&
      this.props.loopQualApp.runTimeKey &&
      this.props.loopQualApp.runTimeKey.EnableHeaderFooter &&
      this.props.loopQualApp.runTimeKey.EnableHeaderFooter === 'Y'
    ) {
      this.setState({ lq4DataTrack: `${this.props.loopQualApp.checkAvailBtnTrack} |footer ` });
    } else {
      this.setState({ lq4DataTrack: `${this.props.loopQualApp.checkAvailBtnTrack} |header ` });
    }
  };

  onClick = (e) => {
    e.preventDefault();
    if (!this.props.modalOverlay.isModalOpened) {
      openModalWindow('LQAPP', this.props.dispatch);
      logMetrics('MiniLq Address Edit', 'MINILQADDRESSCHANGE', 'Info');
      openViewVzTag('lq check avail');
    }
    clearDetails(this.props.dispatch, this.props.cookies, this.props.fiosDetails, this.props.loopQualApp);
    if (this.props.loopQualApp.TWS) {
      if (getTwsStickyLQ() === 'Y') {
        this.props.dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
      } else {
        this.props.dispatch(updateCurrentView(LQ_VIEWS.COMMON_LQ));
      }
    }
  };

  getCtaText = (lqAddress, loopQualApp) => {
    let ctaText = '';
    const pathName = window.location.pathname && window.location.pathname.toLowerCase();
    if (
      loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW &&
      !this.props.bulkQualDetails.isFwaExisting &&
      !(
        this.props.lqResult.fiosQualified ||
        this.props.lqResult.fiveGQualified ||
        this.props.lqResult.qualifiedCBand ||
        this.props.lqResult.lteQualified
      )
    ) {
      ctaText = stickyBannerContent.button_sign_up_new;
      if (loopQualApp.flowType === Flowtype.MOVERS) {
        ctaText = stickyBannerContent.button_sign_up;
      }
    } else if (loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult?.fiosQualification?.pendingOrder === 'Y') {
      ctaText = stickyBannerContent.continue;
    } else if (loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW) {
      ctaText = stickyBannerContent.button_resume;
    } else if (
      lqAddress &&
      this.props.loopQualApp.isLoggedInUserInfo &&
      !this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.flowType !== Flowtype.MOVERS &&
      !this.props.loopQualApp.isACPFlow &&
      !this.props.loopQualApp.TWS &&
      this.props.addressQualification.lqResult.fiosQualified &&
      (loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION || loopQualApp.currentLQView === LQ_VIEWS.BUSINESSADDRESS_VIEW)
    ) {
      ctaText = stickyBannerContent.button_CA;
    } else if (
      loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
      loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW ||
      loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ||
      loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW ||
      loopQualApp.currentLQView === LQ_VIEWS.BUSINESSADDRESS_VIEW
    ) {
      ctaText = stickyBannerContent.button_Order;
    } else if (
      loopQualApp.currentLQView !== LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW &&
      (pathName.endsWith('/home/internet') || pathName.endsWith('/home/internet/'))
    ) {
      ctaText = stickyBannerContent.button_Start;
    } else if (loopQualApp.isLoggedIn && loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      ctaText = stickyBannerContent.button_Order;
    } else {
      ctaText = stickyBannerContent.button_CA;
    }
    return ctaText;
  };

  editKeyDown = (e) => {
    if (e.charCode === 13) {
      this.onClick(e);
    }
  };

  dispatchCtaLabelChangeEvent = () => {
    console.log('====DISPATCHING CTA EVENT====');
    document.dispatchEvent(new CustomEvent('lqw-cta-label-change', { detail: this.ctaLabel, bubbles: true }));
  };

  showAddressBar() {
    if (
      this.props.loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.BUSINESSADDRESS_VIEW ||
      ((this.props.loopQualApp.currentLQView === LQ_VIEWS.LBO_VIEW || this.props.loopQualApp.currentLQView === LQ_VIEWS.BLANK_LOADER_VIEW) &&
        !this.props.addressQualification.lqsTagTriggered)
    ) {
      return true;
    }
    return false;
  }

  hideLQ() {
    this.props.dispatch(updateHideMiniLq(true));
  }

  renderAddressIntrotText() {
    const { lqAddress, displayContent } = this.props;
    let addressIntroText = null;
    const bulkResponse = window.sessionStorage.getItem('LQBulkResponse');
    const lqResponse = window.sessionStorage.getItem('LQResponse');
    const sessionResponse = !!(bulkResponse || lqResponse);
    if (
      this.props.loopQualApp.isLoggedInUserInfo &&
      !this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.flowType !== Flowtype.MOVERS &&
      !this.props.loopQualApp.isACPFlow &&
      !this.props.loopQualApp.TWS &&
      lqAddress &&
      this.props.addressQualification.lqResult.fiosQualified &&
      (this.props.loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.BUSINESSADDRESS_VIEW)
    ) {
      addressIntroText = (
        <Body size="small">
          <b>{lqAddress}</b>
        </Body>
      );
    } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      addressIntroText = (
        <Title size="small" bold>
          {displayContent}
        </Title>
      );
    } else if (
      sessionResponse &&
      lqAddress &&
      !(this.props.loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION && this.props.loopQualApp.lq4)
    ) {
      addressIntroText = (
        <AddressEditable
          intputId="edit-address"
          streetAddress={
            <Body>
              <b>{lqAddress}</b>
            </Body>
          }
          onEditClick={this.onClick}
          data-track={dataTrack('link', this.props.modalOverlay.isModalOpened ? '' : 'edit address - nav')}
          onEditKeyDown={this.editKeyDown}
          notify={this.props.notify}
          loopQualApp={this.props.loopQualApp}
          stickyLq
        />
      );
    } else if (displayContent) {
      addressIntroText = (
        <Title size="small" bold>
          {displayContent}
        </Title>
      );
    }
    return addressIntroText;
  }

  renderAddressIntrotTextMiniLQ() {
    const { lqAddress, displayContent } = this.props;
    let addressIntroTextMiniLQ = null;
    const bulkResponse = window.sessionStorage.getItem('LQBulkResponse');
    const lqResponse = window.sessionStorage.getItem('LQResponse');
    const sessionResponse = !!(bulkResponse || lqResponse);
    if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      addressIntroTextMiniLQ = (
        <Body size="large">
          <b>{displayContent}</b>
        </Body>
      );
    } else if (
      sessionResponse &&
      lqAddress &&
      this.props.loopQualApp.currentLQView !== LQ_VIEWS.ADDRESS_QUALIFICATION &&
      this.props.customerStreetDetails !== null
    ) {
      addressIntroTextMiniLQ = (
        <AddressEditableMinilq
          intputId="edit-address"
          streetAddress={
            <Body>
              <b>{this.props.customerStreetDetails.entireStreetAddress ? this.props.customerStreetDetails.entireStreetAddress : lqAddress}</b>
            </Body>
          }
          onEditClick={this.onClick}
          data-track={dataTrack('link', this.props.modalOverlay.isModalOpened ? '' : 'edit address - nav')}
          onEditKeyDown={this.editKeyDown}
          notify={this.props.notify}
          stickyLq
        />
      );
    } else if (displayContent) {
      addressIntroTextMiniLQ = (
        <Body size="large">
          <b>{displayContent}</b>
        </Body>
      );
    }

    return addressIntroTextMiniLQ;
  }

  renderOtherQualifiedServiceContent(lqWireline) {
    const { lqResult, widgetHost, moveDetails } = this.props;
    const XX_Service = getQualifiedService(lqResult);
    const YY_Service = getServiceName(widgetHost && widgetHost.toUpperCase());
    const bulkResponse = window.sessionStorage.getItem('LQBulkResponse');
    const lqResponse = window.sessionStorage.getItem('LQResponse');
    const sessionResponse = !!(bulkResponse || lqResponse);
    let renderOtherContent = '';
    if (lqWireline) {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>
            This address already has Fios Home Internet.
            <br /> Moving or creating an additional account?
          </Body>
        </OtherServiceContent>
      );
    } else if (lqResult.preOrderInServiceDate) {
      const subheading = `5G Home internet will be launching on ${lqResult.preOrderInServiceDate} at your address. Pre-order now?`;
      renderOtherContent = (
        <OtherServiceContent>
          <Body>
            <b>{subheading}</b>
          </Body>
        </OtherServiceContent>
      );
    } else if (this.props.loopQualApp.flowType === Flowtype.MOVERS && moveDetails.preferredMVService.lineLevelInfo && lqResult.lqAddress !== '') {
      let subheading = '';
      const preferredMVService = moveDetails.preferredMVService.lineLevelInfo.networkBandwidthType
        ? moveDetails.preferredMVService.lineLevelInfo.networkBandwidthType.toLowerCase()
        : moveDetails.preferredMVService.lineLevelInfo.toLowerCase();
      const move_Service = getMVServiceName(preferredMVService);
      if (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
        subheading = this.props.notify.signUpCompleted
          ? "We'll let you know when Verizon Home Internet becomes available at your address."
          : 'Sign up to receive a notification when Verizon Home Internet becomes available at your address.';
      } else if (lqResult.moveQualified && lqResult.networkBandwidthType && preferredMVService === lqResult.networkBandwidthType.toLowerCase()) {
        subheading = `${move_Service} is available at this address.`;
      } else if (
        preferredMVService.includes('mmw') &&
        lqResult.networkBandwidthType &&
        preferredMVService === lqResult.networkBandwidthType.toLowerCase()
      ) {
        subheading = `${move_Service} is available at this address. Call us at 800.922.0204 to complete your move details.`;
      } else {
        subheading = `Place your order for ${XX_Service} now. Don't forget to call us at 800.922.0204 to disconnect service at your current address.`;
      }
      renderOtherContent = (
        <OtherServiceContent>
          <Body>
            <b>{subheading}</b>
          </Body>
        </OtherServiceContent>
      );
    } else if (
      this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW &&
      !(
        this.props.lqResult.fiosQualified ||
        this.props.lqResult.fiveGQualified ||
        this.props.lqResult.qualifiedCBand ||
        this.props.lqResult.lteQualified
      )
    ) {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>
            <b>
              {this.props.notify.signUpCompleted
                ? "We'll let you know when Verizon Home Internet becomes available at your address."
                : 'Get updated on the availability of Verizon Home Services.'}
            </b>
          </Body>
        </OtherServiceContent>
      );
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW) {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>
            <b>Welcome back. It looks like you have a pending order for {XX_Service}. Would you like to continue?</b>
          </Body>
        </OtherServiceContent>
      );
    } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      renderOtherContent = '';
    } else if (sessionResponse && lqResult.fiosQualification?.pendingOrder === 'Y') {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>Looks like you already have a pending order.</Body>
        </OtherServiceContent>
      );
    } else if (sessionResponse && XX_Service.toLowerCase().indexOf(widgetHost) < 0 && XX_Service !== lqServices.all) {
      renderOtherContent = (
        <OtherServiceContent>
          {YY_Service !== lqServices.all && XX_Service !== YY_Service ? (
            <Body>{`Good news, we can offer ${XX_Service} instead of ${YY_Service} at this address.`}</Body>
          ) : (
            <Body>
              Your address qualifies for {XX_Service}.<br />
            </Body>
          )}
        </OtherServiceContent>
      );
    } else if (sessionResponse && XX_Service.toLowerCase().indexOf(widgetHost) > -1 && XX_Service !== lqServices.all) {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>Your address qualifies for {XX_Service}.</Body>
        </OtherServiceContent>
      );
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION) {
      renderOtherContent = (
        <OtherServiceContent reduceSize="90%">
          <Body size="medium">Check if Verizon Home Internet is available.</Body>
        </OtherServiceContent>
      );
    }
    if (this.props.bulkQualDetails && this.props.bulkQualDetails.isFwaExisting) {
      renderOtherContent = (
        <OtherServiceContent>
          <Body>{stickyBannerContent.fwa_existing}</Body>
        </OtherServiceContent>
      );
    }
    return renderOtherContent;
  }

  render() {
    const { lqResult, miniLqConfig, loopQualApp, customerStreetDetails, addressLookup, unitInputError, colors } = this.props;
    const { lqAddress } = lqResult;
    // console.log('restconfig', colors);
    const addressSelected = customerStreetDetails != null;
    miniLqConfig.loopQualApp = loopQualApp;
    const { styling = {} } = miniLqConfig;
    let lqWireline = false;
    if (
      loopQualApp.isLoggedInUserInfo &&
      !loopQualApp.isLoggedIn &&
      loopQualApp.flowType !== Flowtype.MOVERS &&
      !loopQualApp.isACPFlow &&
      !loopQualApp.TWS &&
      lqAddress &&
      this.props.addressQualification.lqResult.fiosQualified &&
      (loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION || loopQualApp.currentLQView === LQ_VIEWS.BUSINESSADDRESS_VIEW)
    ) {
      lqWireline = true;
    }
    let btnText = this.getCtaText(lqAddress, loopQualApp);
    const renderOtherContent = this.renderOtherQualifiedServiceContent(lqWireline);
    if (
      renderOtherContent !== '' &&
      loopQualApp.currentLQView !== LQ_VIEWS.ADDRESS_QUALIFICATION &&
      loopQualApp.currentLQView !== LQ_VIEWS.UN_QUALIFIED_VIEW &&
      loopQualApp.currentLQView !== LQ_VIEWS.RESUME_SMART_CART_VIEW &&
      !(loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult?.fiosQualification?.pendingOrder === 'Y')
    ) {
      btnText = stickyBannerContent.button_Order;
    }

    if (this.ctaLabel && this.ctaLabel !== btnText) {
      this.ctaLabel = btnText;
      this.dispatchCtaLabelChangeEvent();
    } else if (!this.ctaLabel) {
      this.ctaLabel = btnText;
    }
    const extraConfig = { ...miniLqConfig, colors };
    return (
      <>
        {loopQualApp.lq4 ? (
          <StickyBarTreatA {...extraConfig}>
            {this.props.responsiveLQEmbeded && (
              <CloseWrapper>
                <ButtonIcon onClick={() => this.hideLQ()} size="small" kind="ghost" renderIcon={(props) => <Icon name="close" {...props} />} />
              </CloseWrapper>
            )}
            <TwoColWrapper id="two-col-wrapper" justifyNormal={this.showAddressBar()} addressAvailable={lqAddress}>
              <LeftColForExpCol {...styling} addressAvailable={lqAddress || !this.showAddressBar()} autoWidth="auto" colors={colors} id="left-col">
                <LeftColWrapper id="left-content" className="expcol" addressAvailable={lqAddress || !this.showAddressBar()}>
                  {this.renderAddressIntrotText()}
                  {renderOtherContent}
                </LeftColWrapper>
              </LeftColForExpCol>
              <Line />
              <div className="right-minlq-wrapper">
                {this.showAddressBar() && (
                  <div id="expand-contract" className={addressSelected ? 'expanded expanded-unit-address' : 'expanded'}>
                    <AddressLookup
                      loopQualApp={loopQualApp}
                      staticContent={loopQualApp.staticContent.ADDRESS_LOOKUP}
                      noSuggestedAddress={this.props.addressQualification.lqResult.noSuggestedAddress}
                      fromSticky
                      lqWireline={!!lqWireline}
                      colors={colors}
                    />
                    {addressSelected && <UnitLookup colors={this.props.colors} fromSticky />}
                  </div>
                )}
                {/* <Space /> */}
                {this.props.notify.signUpCompleted &&
                loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW &&
                !(
                  this.props.lqResult.fiosQualified ||
                  this.props.lqResult.fiveGQualified ||
                  this.props.lqResult.qualifiedCBand ||
                  this.props.lqResult.lteQualified
                ) ? (
                  <></>
                ) : (
                  <RightColForExpand
                    className={
                      (addressLookup && addressLookup.streetError) || unitInputError
                        ? 'sticyBarCtaDiv'
                        : btnText === 'Get updates'
                        ? 'getUpdates'
                        : ''
                    }
                  >
                    <StyledButton
                      id="stickyBar-CTA"
                      onClick={this.props.onClick}
                      onMouseOver={this.props.onMouseOver}
                      secondary={false}
                      inverted={false}
                      onKeyDown={() => greyRemove()}
                      colors={colors}
                      surface={colors?.surface || 'dark'}
                      data-track={dataTrackwithDetail('link', this.state.lq4DataTrack, 'lq sticky bar')}
                      ariaLabel={btnText === 'Check availability' ? 'Check availability to Get Verizon Home Internet' : btnText}
                    >
                      {btnText}
                    </StyledButton>{' '}
                    <Space />
                  </RightColForExpand>
                )}
              </div>
            </TwoColWrapper>
          </StickyBarTreatA>
        ) : (
          <StickyBarTreatA {...extraConfig}>
            <TwoColWrapper id="two-col-wrapper">
              <LeftColForReskin {...styling} colors={colors} id="left-col">
                <div id="left-content" className="reskin">
                  {this.renderAddressIntrotTextMiniLQ()}
                  <div className="parent">
                    <div className={this.props.notify.signUpCompleted ? 'child float-left-signup-child' : 'child float-left-child'}>
                      {renderOtherContent}
                    </div>
                  </div>
                </div>
              </LeftColForReskin>
              <RightCol>
                <div>
                  <StyledButton
                    size="small"
                    display={this.props.notify.signUpCompleted ? 'none' : 'flex'}
                    id="stickyBar-CTA"
                    onClick={this.props.onClick}
                    onMouseOver={this.props.onMouseOver}
                    surface={colors?.surface || 'dark'}
                    colors={colors}
                    secondary={(styling.ctaType && styling.ctaType.secondary) || true}
                    inverted={(styling.ctaType && styling.ctaType.inverted) || true}
                    data-track={dataTrack('link', btnText === 'Get updates' || btnText === 'Resume order' ? btnText : loopQualApp.checkAvailBtnTrack)}
                  >
                    {btnText}
                  </StyledButton>
                </div>
                <PaddingforLinkText>
                  {!this.props.lqAddress &&
                  (this.props.loopQualApp.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION ||
                    loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW ||
                    this.props.loopQualApp.currentLQView === LQ_VIEWS.COMMON_LQ) ? (
                    <div id="ae-edit-SignUpwrapper" />
                  ) : (
                    <div id="ae-edit-wrapper">
                      <Link
                        surface="dark"
                        id="edit-address"
                        ariaLabel="Edit"
                        onClick={(e) => this.onClick(e)}
                        onKeyPress={(e) => this.editKeyDown(e)}
                        linkText="Change address"
                        style={{ marginBottom: '6px', color: 'white ! important' }}
                        data-track={dataTrack('link', 'Change Address - lq widget')}
                        role="button"
                      />
                    </div>
                  )}
                </PaddingforLinkText>
              </RightCol>
            </TwoColWrapper>
          </StickyBarTreatA>
        )}
      </>
    );
  }
}

export default MiniLq;

MiniLq.propTypes = {
  widgetHost: PropTypes.string,
  dispatch: PropTypes.string,
  loopQualApp: PropTypes.string,
  addressQualification: PropTypes.string,
  cookies: PropTypes.string,
  lqAddress: PropTypes.string,
  displayContent: PropTypes.string,
  lqResult: PropTypes.string,
  multiServiceDetails: PropTypes.string,
  onClick: PropTypes.string,
  miniLqConfig: PropTypes.string,
  bulkQualDetails: PropTypes.string,
  notify: PropTypes.string,
  fiosDetails: PropTypes.string,
  moveDetails: PropTypes.object,
  onMouseOver: PropTypes.func,
  modalOverlay: PropTypes.object,
  addressLookup: PropTypes.object,
  unitInputError: PropTypes.bool,
  customerStreetDetails: PropTypes.object,
  responsiveLQEmbeded: PropTypes.any,
  colors: PropTypes.any,
};
