import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalTitle, ModalBody } from '@vds/modals';
import { ButtonIcon } from '@vds/button-icons';
import styled from 'styled-components';
import Icon from '@vds/icons';
import { closeModalWindow, existingCustomerModal } from './action';
import { dataTrack } from '../../../utils/vzdl';
import { getDomainUrl, prodEspanolDomain } from '../../../constants/api-urls';
import { Pages, Platforms, Flowtype } from '../../../constants/common';
import { redirectToPlansPage, setToStorage, isMYVZWAPP } from '../../../utils/lqServiceUtil';
import '../../../assets/css/common.css';

const CloseIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  z-index: 10;
  :hover * {
    stroke-width: 2;
  }
`;
const ModalTitleThreeDotO = styled.div`
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 300;
  font-family: Verizon-NHG-eDS;
  padding: 0px 0px 0px 4px;
`;

class modalOverlay extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('popstate', this.handlePopState);
    const body = document.querySelector('body');
    if (body.classList.contains('hide-focus-outline')) {
      setTimeout(() => {
        const modalContainer = document.querySelector('#lqw-modal-container');
        const input = modalContainer && modalContainer.querySelector('input');
        const closeButton = document.querySelector('.closeButton');
        if (input) {
          input.scrollTop = 0;
        }
        if (closeButton) {
          closeButton.focus();
        }
      }, 300);
    }
  }

  componentDidUpdate() {
    if (document.getElementById('modal-close-btn')) {
      document.getElementById('modal-close-btn').setAttribute('aria-label', 'Press enter or escape key to close the modal');
    }
    if (window.vzlqw) {
      window.vzlqw.isvzlqwModalOpened = true;
      document.getElementById('modalTitleHeading').setAttribute('aria-label', `${this.props.modalTitle}`);
      if (window.chatWindowInBackGround) {
        window.chatWindowInBackGround(true);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('popstate', this.handlePopState);
  }

  onCloseModal = (e) => {
    e.preventDefault();
    this.closeModal();
    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }
  };

  onKeyDown = (e) => {
    const highlightedItem = document.getElementById('selectedOption');
    if (!highlightedItem) {
      if (e.keyCode === 27 && this.props.modalOverlay.isModalOpened) {
        this.closeModal();
      } else if (e.keyCode === 40 || e.keyCode === 38) {
        //
      }
    }
  };

  handlePopState = () => {
    if (this.props.modalOverlay.isModalOpened) {
      this.closeModal();
    }
  };

  /* onCloseKeyDown = (e) => {
    const body = document.querySelector('body');
    if (e.charCode === 13 && !body.classList.contains('hide-focus-outline')) {
      this.closeModal();
    }
  }; */

  closeModal() {
    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }
    if (
      this.props.loopQualApp.isACPFlow ||
      this.props.loopQualApp.isComboMFJT ||
      this.props.loopQualApp.storeLocater ||
      this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC' ||
      this.props.loopQualApp.TWS
    ) {
      if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC') {
        const cpcGetStart = document.getElementById('addHomeInternetID');
        if (cpcGetStart) {
          setTimeout(() => cpcGetStart.focus(), 10);
        }
      }
      if (!(isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC')) {
        closeModalWindow(this.props.dispatch);
      }
      return true;
    }
    const redirectUrl = prodEspanolDomain() ? '' : getDomainUrl();
    const pathName = window.location.pathname && window.location.pathname.toLowerCase();
    if (this.props.loopQualApp.flowType !== Flowtype.MOVERS) {
      if (
        (this.props.addressQualification.lqResult.qualifiedCBand || this.props.addressQualification.lqResult.fiveGQualified) &&
        pathName !== Pages.redirectToFiveGHome
      ) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToFiveGHome}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToFiveGHome, '_blank');
        }
      } else if (this.props.addressQualification.lqResult.lteQualified && pathName !== Pages.redirectToLte) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToLte}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToLte, '_blank');
        }
      } else if (
        this.props.addressQualification.lqResult.fiosQualified &&
        pathName !== Pages.redirectToFios &&
        this.props.loopQualApp.lqPlatform !== Platforms.FIOS
      ) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToFios}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToFios, '_blank');
        }
      } else {
        const cpcGetStart = document.getElementById('addHomeInternetID');
        if (cpcGetStart) {
          setTimeout(() => cpcGetStart.focus(), 10);
        }
        const learnVzdlObj = window.sessionStorage.getItem('learnVzdlObj');
        if (learnVzdlObj) {
          window.vzdl = JSON.parse(learnVzdlObj);
          setToStorage('learnVzdlObj', '');
        }
      }
    }
    closeModalWindow(this.props.dispatch);
  }

  renderCloseButton = () => (
    <CloseIcon>
      <ButtonIcon
        size="large"
        ariaLabel="close"
        className="closeButton"
        onClick={this.onCloseModal}
        tabIndex={0}
        data-track={dataTrack('link', 'close- widget')}
        renderIcon={(props) =>
          !window.location.href.includes('cpcCheckAvailability') && !window.location.pathname.includes('inhome/') && <Icon name="close" {...props} />
        }
      />
    </CloseIcon>
  );

  render() {
    const open = this.props.modalOverlay.isModalOpened;
    const template = this.props.modalBody || '';
    const modalTitle = this.props.modalTitle || this.props.modalOverlay.modalTitle || '';
    const isVfEmailFlow = modalTitle.indexOf('Verizon Forward discount.') > -1;
    const modalContent = (
      <div tabIndex="-1">
        <Modal
          id="lq20"
          zIndex="9999"
          className="lq20 modalHeight"
          contentContainer="lqw-modal-container"
          opened={open}
          closeButton={
            this.props.loopQualApp.sourceInfo !== 'digitalCombo' &&
            !window.location.href.includes('cpcCheckAvailability') &&
            !window.location.pathname.includes('inhome/')
              ? this.renderCloseButton()
              : null
          }
          disableOutsideClick
          modalId={this.props.modalOverlay.modalId}
          ariaLabel="modal"
          customScroll
        >
          <ModalTitle primitive="h2">
            <ModalTitleThreeDotO>
              <div id="modalTitleHeading" aria-label={modalTitle} className="modalTitleHeading" style={{ fontWeight: isVfEmailFlow && '300' }}>
                {modalTitle}
              </div>
            </ModalTitleThreeDotO>
          </ModalTitle>
          <ModalBody>{template}</ModalBody>
        </Modal>
      </div>
    );
    return (
      <div
        role="dialog"
        aria-modal="true"
        id="modal-container"
        tabIndex="-1"
        aria-hidden={modalContent ? 'false' : 'true'}
        style={{ display: modalContent ? 'block' : 'none' }}
      >
        {modalContent}
      </div>
    );
  }
}
export default modalOverlay;
modalOverlay.propTypes = {
  modalOverlay: PropTypes.string,
  modalBody: PropTypes.string,
  dispatch: PropTypes.string,
  modalTitle: PropTypes.string,
  loopQualApp: PropTypes.string,
  addressQualification: PropTypes.object,
};
