import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducers/configure-store';
import LoopQualApp from './components/loop-qual-app/index';
import { getRenderSettings, getTwsStickyLQ, getSourceInfo } from './config';
import ErrorBoundary from './ErrorBoundary';

const App = () => {
  const renderSettings = getRenderSettings();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isResponsiveLQ = getSourceInfo() === 'ResponsiveLQ' || urlSearchParams.get('type') === 'ResponsiveLQ';
  const extraProps = {
    ...renderSettings,
    miniLq: { ...renderSettings.miniLq, enabled: renderSettings.miniLq.enabled || getTwsStickyLQ() === 'Y' || isResponsiveLQ },
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <LoopQualApp {...extraProps} />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default App;
